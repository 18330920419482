import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout/Layout';
import ReactMarkdown from 'react-markdown';
import Seo from '../components/Seo';
import ServicesGrid from '../components/servicesGrid';
import Blog from '../components/sections/blog';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from '../../node_modules/react-syntax-highlighter/dist/cjs/styles/prism';
import ContactUs from '../components/sections/contactUs';
import Share from '../components/article/socialShare';
import PropTypes from 'prop-types';

function ArticleTemplate({ data, pageContext }) {
  const image = data.strapiArticles.image
    ? data.strapiArticles.image.localFile.childImageSharp.resize
    : null;

    function CodeBlock({ language = null, value }) {
      return (
        <SyntaxHighlighter language={language} style={dracula}>
          {value}
        </SyntaxHighlighter>
      );
    }
    
    CodeBlock.propTypes = {
      value: PropTypes.string.isRequired,
      language: PropTypes.string,
    }
    
  return (
    <Layout>
      <Seo
        title={data.strapiArticles.title}
        description={data.strapiArticles.description}
        image={data.strapiArticles.image.localFile.childImageSharp.resize}
        url={`https://cantabriadigital.es/${pageContext.slug}`}
      />
      <article className="prose lg:prose-2xl mx-auto my-0 md:my-10">
        <GatsbyImage
          alt={`Header image ${data.strapiArticles.title}`}
          image={data.strapiArticles.image.localFile.childImageSharp.gatsbyImageData}
          className="rounded-lg my-2"
        />
        <div className="px-4">
          <h1 className="text-center font-bold text-2xl md:text-3xl lg:text-4xl">
            {data.strapiArticles.title}
          </h1>
          <p>
            Escrito por{' '}
            <Link to={`/authors/${data.strapiArticles.author.username}/`}>
              {data.strapiArticles.author.username}
            </Link>
          </p>
          <ReactMarkdown
            className="post-content"
            renderers={{
              code: CodeBlock
            }}
            source={data.strapiArticles.content}
          />
        </div>
      </article>
      <Share
        url={`https://cantabriadigital.es/${pageContext.slug}`}
        image={`https://cantabriadigital.es/${data.strapiArticles.image.localFile.childImageSharp.resize.src}`}
        title={data.strapiArticles.title}
      />
      <ServicesGrid />
      <Blog />
    </Layout>
  );
}

export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticles(id: { eq: $id }) {
      title
      content
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 960, layout: CONSTRAINED)
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      author {
        id
        username
      }
    }
  }
`;
