import React from 'react';
import { useForm } from "react-hook-form";

function Contact(){ 
    const { register, handleSubmit } = useForm();
    const onSubmit = data => {
        console.log(data);
        alert(JSON.stringify(data));
    };

  return(
  <section id="contact" className="bg-white py-16 md:py-24 lg:py-36 px-6 md:px-0">
    <div className="container mx-auto max-w-6xl ">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold mb-6 ">Metodos de Contacto</h2>
        <p className="md:max-w-lg text-center mx-auto">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid est dignissimos perspiciatis. Assumenda laudantium ea est. Placeat perferendis eaque ratione, molestias nihil provident, in delectus non ad tempora repellendus neque?</p>
      </div>
    </div>    

    <form className="my-2 lg:my-6 gap-6 p-6 mx-auto container md:max-w-lg" onSubmit={handleSubmit(onSubmit)}>
        <div>
            <span className="uppercase text-sm text-gray-600 font-bold">Nombre Completo</span>  
            <input 
                className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text" 
                name="name"
                placeholder="Nombre Completo"
                ref={register({required: true, maxLength: 80})}
            />
        </div>

        <div className="mt-8">
            <span className="uppercase text-sm text-gray-600 font-bold">Correo</span>
            <input 
                className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                type="text"
                name="email"
                ref={register({required: true, pattern: /^\S+@\S+$/i})}
            />
        </div>

        <div className="mt-8">
        <span className="uppercase text-sm text-gray-600 font-bold">Telefono</span>
        <input className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
            type="text"/>
        </div>

        <div className="mt-8">
        <span className="uppercase text-sm text-gray-600 font-bold">Mensaje</span>
        <textarea
            className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
        </div>

        <div className="mt-8">
            <button
                type="submit"
                className="uppercase text-sm font-bold tracking-wide bg-green-500 text-black p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                
            >
                Enviar
            </button>
        </div>   
    </form>
  </section>
);}


export default Contact;
