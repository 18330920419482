import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import {faFacebookF,faTwitter, faLinkedin, faPinterest} from '@fortawesome/free-brands-svg-icons' 
import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
class SocialShare extends Component {
    constructor(props) {
        super(props)
        library.add(faPinterest, faTwitter, faFacebookF, faLinkedin, faEnvelope)
        this.state = {
            shareLinks: [
                {
                  url: `https://www.facebook.com/sharer/sharer.php?u=${this.props.url}`,
                  type: 'fab',
                  website: 'facebook-f',
                },
                {
                  url: `https://twitter.com/intent/tweet?params=&text=Echad+un+vistazo+a+este+articulo&url=${this.props.url}`,
                  type: 'fab',
                  website: 'twitter',
                },
                {
                  url: `https://www.linkedin.com/sharing/share-offsite/?url=${
                    this.props.url
                    }`,
                  type: 'fab',
                  website: 'linkedin',
                },
                {
                  url: `https://pinterest.com/pin/create/button/?url=${this.props.url}
                        &media=${this.props.image}
                        &description=${this.props.title}`,
                  type: 'fab',
                  website: 'pinterest',
                },
                {
                  url: `mailto:?&body=Check%20this%20article%20out!%0A%0A${
                    this.props.url
                    }`,
                  type: 'fas',
                  website: 'envelope',
                },
            ],
        }
        this.openWindow = this.openWindow.bind(this)
        this.socialScroll = this.socialScroll.bind(this)
    }
    
    componentDidMount() {
      if(typeof window !== "undefined"){
        window.addEventListener('scroll', this.socialScroll)
      }    
    }

    componentWillMount() {
      if(typeof window !== "undefined"){
        window.addEventListener('scroll', this.socialScroll)
      }    
    }
    
    socialScroll(e) {
        const top = window.pageYOffset
        const bottom = top + window.innerHeight
        const socialEl = document.querySelector('.post-content')
    
        if (document.body.contains(socialEl)) {
          const socialContent = document.querySelector('.post-content').offsetTop + 550
          const footer = document.querySelector('.footer').offsetTop - 200
          const socialShare = document.querySelector('#socialShare')
    
          if (top > socialContent && bottom < footer) {
            socialShare.classList.add('is-shareable')
          } else {
            socialShare.classList.remove('is-shareable')
          }
        }
      }

    openWindow(url) {
        window.open(url, 'popup', 'width=600,height=600')
        return false
    }
    
    render() {
        return (
            <div id="socialShare" className="socialShare" style={{ visibility: 'hidden' }}>
                <div className="flex flex-row lg:flex-col justify-center is-mobile">
                    {this.state.shareLinks.map(link => (
                    <a
                        key={link.website}
                        className="social-icon text-center p-2 mx-1 rounded-lg my-1 bg-green-500 hover:bg-green-600 transition duration-500"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={[link.type, link.website]} size="2x" />
                    </a>
                    ))}
                </div>
            </div>           
        )
    }
}

export default SocialShare